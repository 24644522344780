@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&display=swap');
@import url('font-awesome/css/font-awesome.min.css');
@import url('./all.css');
body {
  background-color: #000000!important;
}
img {
  width:100%;
}

a {
  text-decoration: none !important;
}

p{
  color: #bebebe!important;
}
h4,h6 {
  color: #dea62d!important;
}

a:hover {
  text-decoration: none !important;
}

.navbar-toggler-icon {
  background-image:url('../src/assets/img/toggler.png') !important;
}

/*/ === home page css starts === /*/

.home-logo {
  height: 60vh;
  padding: 60px 15px;
  background: #000000;
  /* background: -moz-linear-gradient(45deg,  #151515 0%, #3f3f3f 33%, #3f3f3f 50%, #3f3f3f 72%, #000000 100%);
  background: -webkit-linear-gradient(45deg,  #151515 0%,#3f3f3f 33%,#3f3f3f 50%,#3f3f3f 72%,#000000 100%);
  background: linear-gradient(45deg,  #151515 0%,#3f3f3f 33%,#3f3f3f 50%,#3f3f3f 72%,#000000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#151515', endColorstr='#000000',GradientType=1 );  */
}

.home-logo img {
  width:150px;
  display: block;
  margin: auto;
}

.home-logo .text-logo {
  width: 80%;
}

.home-logo h4 {
  color:#dea62d;
  text-align: center;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.home-slider img {
  height: 100vh;
  object-fit: cover;
}

.home-slider {
  position: relative;
}

.navbar-nav a {
  color:#fff;
  font-family: 'Roboto', sans-serif;
  transition: 0.2s ease;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 18px;
}

.navbar-nav a:hover {
  color:rgb(69, 69, 69);
}

.home-slider .reg-btn button {
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  color:#000;
  text-decoration: none;
  position: absolute;
  padding: 13px 35px;
  bottom: 40px;
  left: 110px;
  border: none;
}

.home-slider .social-media-icons {
  display: flex;
  flex-direction: column;
  background-color: #000;
  width: 65px;
  text-align: center;
  padding: 12px 5px 5px;
  position: absolute;
  bottom: 0px;
}

.home-slider .social-media-icons svg {
  fill:#dea62d;
  width:45px;
  height:45px;
  border:1px solid #dea62d;
  border-radius: 50px;
  padding:10px;
  margin-bottom: 10px;
}

.home-events {
  height: 40vh;
  overflow: hidden;
}

.home-events h4 {
  margin-bottom: 0px;
  color:#dea62d;
  background: #151515;
  background: -moz-linear-gradient(45deg,  #151515 0%, #3f3f3f 33%, #3f3f3f 50%, #3f3f3f 72%, #000000 100%);
  background: -webkit-linear-gradient(45deg,  #151515 0%,#3f3f3f 33%,#3f3f3f 50%,#3f3f3f 72%,#000000 100%);
  background: linear-gradient(45deg,  #151515 0%,#3f3f3f 33%,#3f3f3f 50%,#3f3f3f 72%,#000000 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#151515', endColorstr='#000000',GradientType=1 ); 
  font-family: 'Bebas Neue', cursive;
  font-size: 24px;
  padding: 10px 20px;
  display: none;
}

.sl-section-right {
  position: relative;
}

.sl-section-right button {
  border:none;
}

.sl-section-right .navbar {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 52vh;
}

.sl-section-right .navbar-toggler-icon {
  background-image: url('assets/img/toggle-bar1.svg') !important;
}

.sl-section-right .navbar button {
  box-shadow:none;
}

.offcanvas-end {
  background-color: #000 !important;
}

.offcanvas-header .btn-close {
  background-image: url('assets/img/toggler-close.png') !important;
  opacity: 0.8;
}

.offcanvas-body .nav-link {
  color:#fff;
  font-family: 'Roboto', sans-serif;
}

.offcanvas {
  width: 380px !important;
}

/*/ --- home about section css starts --- /*/

.home-about {
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 75%, rgba(24,24,24,1) 75%, rgba(24,24,24,1) 100%);
}

/* .home-about .about-img:before {
  content: '';
  display: block;
  width: 30%;
  height: 100%;
  background-color:#181818;
} */

.home-about .custom-container {
  padding: 70px 7%;
}

.home-about .about-content h4 {
  color: #dea62d;
  font-family: 'Bebas Neue', cursive;
  font-size: 40px;
}

.home-about .about-content p {
  font-family: 'Roboto', sans-serif;
  color:#5d5d5d;
  font-size: 16px;
  text-align: justify;
}

.home-about .about-content a {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #5d5d5d;
  padding: 13px 37px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin-bottom: 10px;
}

.home-about .about-content a:after {
  content:'';
  display: block;
  background-image:url('assets/img/btn-shape.png');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size:contain;
}
/*/ --- home about section css ends --- /*/


/*/ --- home events section css starts --- /*/
.home-events-main {
  background-color:#000;
}
.home-events-main .events-banner {
  background-image:url('assets/img/event-banner-bg.jpg');
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
  padding:150px 20px 20px !important;
}

.home-events-main .events-banner h4 {
  color: #dea62d;
  font-family: 'Bebas Neue', cursive;
  font-size: 40px;
  text-align: center;
}

.home-events-main .events-list .custom-container {
  padding: 40px 8%;
}

.home-events-main .events-list .events-box {
  position: relative;
}

.home-events-main .events-list .events-box img {
  height: 300px;
  object-fit: cover;
}

.home-events-main .events-list .events-box h6 {
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  font-weight: 500;
  color: #2a2a2a;
  padding: 10px 20px;
  position: absolute;
  top: 240px;
  left: 22px;
}

.home-events-main .events-list .events-box p {
  font-family: 'Roboto', sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
  margin-top: 20px;
}

.home-events-main .events-list .event-btn {
  border:none;
  border-top: 1px solid #1c1c1c;
  border-bottom: 1px solid #1c1c1c;
  margin:40px 0px 20px;
  padding: 20px 5px;
}

.home-events-main .events-list .event-btn a {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #5d5d5d;
  padding: 13px 37px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin: auto;
}

.home-events-main .events-list .event-btn a:after {
  content:'';
  display: block;
  background-image:url('assets/img/btn-shape.png');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size:contain;
}
/*/ --- home events section css ends --- /*/


/*/ --- home committee section css starts --- /*/
.home-committee {
  background-color:#000;
}

.home-committee .custom-container {
  padding: 40px 8%;
}

.home-committee h4 {
  color: #dea62d;
  font-family: 'Bebas Neue', cursive;
  font-size: 40px;
  text-align: center;
  margin-bottom: 20px;
}

.home-committee .committee-row {
  display: flex;
  flex-wrap: wrap;
}

.home-committee .member-box {
  width: 20%;
  padding: 20px;
}

.home-committee .other-committee-box {
  margin-top: 22px;
}

.home-committee .chairman img {
  height: 260px !important;
}

.home-committee .member-box img {
  height: 215px;
  object-fit: cover;
  filter: grayscale(1);
}

.home-committee .member-box h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color:#5d5d5d;
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
}

.home-committee .member-box p {
  font-family: 'Roboto', sans-serif;
  color:#303030;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
}
/*/ --- home committee section css ends --- /*/


/*/ --- home service css starts --- /*/
.home-service {
  background: rgb(24,24,24);
  background: linear-gradient(90deg, rgba(24,24,24,1) 0%, rgba(24,24,24,1) 35%, rgba(0,0,0,1) 35%, rgba(0,0,0,1) 100%);
}

.home-service .custom-container {
  padding: 40px 8%;
}

.home-service .service-content {
  background-color:#181818;
  padding: 75px 9%;
  height: 100%;
}

.home-service .service-content h4 {
  color: #dea62d;
  font-family: 'Bebas Neue', cursive;
  font-size: 40px;
}

.home-service .service-content p {
  font-family: 'Roboto', sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
  margin-top: 20px;
}

.home-service .service-img img {
  height: 440px;
  object-fit: cover;
}

.home-service .service-content a {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #5d5d5d;
  padding: 13px 27px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
}

.home-service .service-content a:after {
  content:'';
  display: block;
  background-image:url('assets/img/btn-shape.png');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size:contain;
}
/*/ --- home service css ends --- /*/


/*/ --- home form css starts --- /*/
.home-register {
  background: rgb(24,24,24);
  background: linear-gradient(90deg, rgba(24,24,24,1) 0%, rgba(24,24,24,1) 35%, rgba(0,0,0,1) 35%, rgba(0,0,0,1) 100%);
}

.home-register .custom-container {
  padding: 40px 8% 120px;
}

.home-register:after {
  content:'';
  display: block;
  width: 100%;
  height: 125px;
  background-color:#000;
}

.home-register .form-box {
  padding-left: 60px;
}

.home-register .form-box h4 {
  color: #dea62d;
  font-family: 'Bebas Neue', cursive;
  font-size: 40px;
  text-align: left;
  margin-bottom: 20px;
}

.home-register .form-box a {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #5d5d5d;
  padding: 21px 35px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  font-size: 20px;
  margin-bottom: 10px;
}

.home-register .form-box a:after {
  content: '';
  display: block;
  background-image: url('assets/img/btn-shape.png');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.home-register .form-box p {
  font-family: 'Roboto', sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
  margin-top: 20px;
}

.home-register .form-img img {
  height: 370px;
  object-fit: cover;
}

.home-register .form-box input {
  border:2px solid #181818;
  font-family: 'Roboto', sans-serif;
  background-color:#000000;
  border-radius:0px;
  height:55px;
  margin-bottom: 20px;
  color:#7e7e7e;
}

.home-register .form-box input:focus-within {
  box-shadow:none;
}

.home-register .form-box input[type="textarea"] {
  height:175px;
  overflow-y: scroll;
}

.home-register .form-box::placeholder{
  font-family: 'Roboto', sans-serif;
  color:#2e2e2e !important;
  font-size: 14px;
}

.home-register .form-box button {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #5d5d5d;
  padding: 16px 44px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  background-color:#000;
  border:none;
  margin-left: 12px;
}

.home-register .form-box button:after {
  content:'';
  display: block;
  background-image:url('assets/img/btn-shape.png');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size:contain;
}
/*/ --- home form css ends --- /*/


/*/ --- footer css starts --- /*/
.footer {
  background-image:url('assets/img/footer-bg.jpg');
  background-repeat:no-repeat;
  background-size:cover;
  background-position: top;
}

.footer .custom-container {
  padding: 40px 8%;
}

.footer .footer-logo {
  width: 140px;
  display: block;
  margin: -105px auto 0px;
}

.footer .footer-nav {
  text-align: center;
  padding: 30px 0px 20px;
}

.footer .footer-nav a {
  font-family: 'Roboto', sans-serif;
  color:#fff;
  font-size: 14px;
  border-right: 1px solid #a3a3a3;
  font-weight: 300;
  padding: 0px 35px;
}

.footer .footer-nav a:last-child {
  border-right:none;
}

.footer .copyright {
  padding: 30px 7%;
  background-color:#0d0d0d52;
}

.footer .copyright p {
  color: #505050;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0px;
}

.footer .copyright a {
  color: #dea62d;
}

.footer .copyright .ft-right p {
  text-align: right;
}

/*/ --- footer css ends --- /*/


/*/ === home page css ends === /*/


/*/ === inner navbar css starts === /*/
.inner-navbar {
  background-color:#000;
  padding: 5px 7% !important;
}

.navbar-fixed-top.scrolled-inner {
  background-color: #000 !important;
  transition: background-color 200ms linear;
  -webkit-box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
  -moz-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.1);
  box-shadow: 0px 5px 15px 0px rgb(0 0 0 / 10%);
  position: fixed;
  width: 100%;
  z-index: 99;
}

.inner-navbar .navbar-nav a {
  color:#fff;
  font-family: 'Roboto', sans-serif;
  transition: 0.2s ease;
  padding: 10px 20px;
  font-weight: 400;
  font-size: 16px;
}

.inner-navbar .navbar-brand {
  width: 70px;
}

.inner-navbar .navbar-nav {
  margin-left: auto;
  margin-right: 0px !important;
}

/*/ === inner navbar css ends === /*/


/*/ === about page css starts === /*/
.about-banner {
  padding:0px 0% 50px !important;
  background-color:#000;
}

.about-banner .banner-bg {
  background-image:url('assets/img/about-banner.jpg');
  background-position:center;
  background-repeat:no-repeat;
  background-size: cover;
}

.register-bg {
  background-image:url('assets/img/RegisterBanner.jpg')!important;
}

.gallery-bg{
  background-image:url('assets/img/GalleryBanner.jpg')!important;
}

.event-bg {
  background-image:url('assets/img/EventBanner.jpg')!important;
}
.member-bg {
  background-image:url('assets/img/MembersBanner.jpg')!important;
}
.contact-bg {
  background-image:url('assets/img/Contactbanner.jpg')!important;
}
.about-banner .banner-bg h1 {
  font-family: 'Bebas Neue', cursive;
  text-align: center;
  color: #fff;
  padding: 100px 20px;
  font-size: 90px;
}

.main-about {
  background-color:#000;
}

.main-about .custom-container {
  padding:40px 7%;
}

.main-about .about-img img {
  height:500px;
  object-fit: cover;
}

.main-about .about-img {
  padding: 0px 8%;
}

.main-about .about-content {
  padding-right: 8%;
}

.main-about .about-content h6 {
  font-family: 'Bebas Neue', cursive;
  color:#fff;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 30px;
}

.main-about .about-content h4 {
  color: #dea62d;
  font-family: 'Bebas Neue', cursive;
  font-size: 50px;
}

.main-about .about-content p {
  font-family: 'Roboto', sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
}

.main-vision-mission {
  background-color:#000;
  background-image:url('../src/assets/img/b-bg.png');
  background-position-y: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.main-vision-mission .custom-container {
  padding: 40px 7% 90px;
}

.main-vision-mission .cap-box h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  font-size: 60px;
  color: #ececec;
}

.main-vision-mission .cap-box h1:before {
  content: '';
  display: block;
  width: 2px;
  height: 110px;
  background-color: #5a5a5a;
}

.main-vision-mission .content-box h4 {
  font-family: 'Bebas Neue', cursive;
  color:#aeaeae;
  font-size: 24px;
}

.main-vision-mission .content-box p {
  font-family: 'Roboto', sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
}

.main-vision-mission .content-box {
  padding: 30px 6%;
  border-bottom:1px solid #181818;
}

.main-vision-mission .content-box:last-child {
  border-bottom: 0px;
}
/*/ === about page css ends === /*/


/*/ === events page css starts === /*/
.events-banner {
  padding:0px 0% 50px !important;
  background-color:#000;
}

.events-banner .banner-bg {
  background-image:url('assets/img/events-banner.jpg');
  background-position:center;
  background-repeat:no-repeat;
  background-size: cover;
}

.events-banner .banner-bg h1 {
  font-family: 'Bebas Neue', cursive;
  text-align: center;
  color: #fff;
  padding: 100px 20px;
  font-size: 90px;
}

.main-events {
  background-color:#000;
}

.main-events .custom-container {
  padding:40px 10%;
}

.main-events .event-box .event-img img {
  height:350px;
  object-fit: cover;
}

.main-events .event-box .event-content {
  padding:20px;
}

.main-events .event-box .event-content h6 {
  font-family: 'Roboto', sans-serif;
  color: #cbcbcb;
  font-weight: 300;
  font-size: 14px;
  position: relative;
}

.main-events .event-box .event-content h6:before {
  content: '';
  display: block;
  width: 100px;
  height: 0.5px;
  background-color: #868686;
  position: absolute;
  left: -107px;
  top: 7px;
}

.main-events .event-box .event-content h4 {
  font-family: 'Bebas Neue', cursive;
  color: #fff;
  font-weight: 300;
  font-size: 30px;
  margin:30px 0px;
}

.main-events .event-box .event-content p {
  font-family: 'Roboto', sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: left;
}

.main-events .event-box .event-content button {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #958e8e;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  border: none;
  background: none;
}

.main-events .event-box {
  margin-bottom: 60px;
}

.main-events .event-box .event-content a:hover svg {
  margin-left: 10px;
  transition: 0.3s ease-in;
}

/*/ --- events box two starts --- /*/

.main-events .event-box-two .event-img img {
  height:350px;
  object-fit: cover;
}

.main-events .event-box-two .event-content {
  padding:20px;
}

.main-events .event-box-two .event-content h6 {
  font-family: 'Roboto', sans-serif;
  color: #cbcbcb;
  font-weight: 300;
  font-size: 14px;
  position: relative;
  text-align: right;
}

.main-events .event-box-two .event-content h6:before {
  content: '';
  display: block;
  width: 100px;
  height: 0.5px;
  background-color: #868686;
  position: absolute;
  right: -107px;
  top: 7px;
}

.main-events .event-box-two .event-content h4 {
  font-family: 'Bebas Neue', cursive;
  color: #fff;
  font-weight: 300;
  font-size: 30px;
  margin:30px 0px;
  text-align: right;
}

.main-events .event-box-two .event-content p {
  font-family: 'Roboto', sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: right;
}

.main-events .event-box-two .event-content button {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #958e8e;;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  border: none;
  background: none;
  float: right;
}

.main-events .event-box-two {
  margin-bottom: 60px;
}

.main-events .event-box-two .event-content a:hover svg {
  margin-right: 10px;
  transition: 0.3s ease-in;
}

/*/ --- events box two ends --- /*/

/*/ === events page css ends === /*/



/*/ === events detail page css starts === /*/
.events-detail-main {
  background-color:#000;
  background-image:url('../src/assets/img/b-bg.png');
  background-position-y: bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

.events-detail-main .custom-container {
  padding: 40px 7%;
}

.events-detail-main .event-content {
  padding-top: 70px;
}

.events-detail-main .event-content h4 {
  color: #ececec;
  font-family: 'Bebas Neue', cursive;
  font-size: 50px;
}

.events-detail-main .event-content p {
  font-family: 'Roboto', sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
}

.events-detail-main .event-date {
  padding-top: 70px;
}

.events-detail-main .event-date h6 {
  font-family: 'Roboto', sans-serif;
  color: #f5f5f5!important;;
  font-weight: 300;
  font-size: 16px;
}

.events-detail-main .event-date h4 {
  font-family: 'Roboto', sans-serif;
  color:#ececec;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 20px;
}

.events-detail-main .event-img {
  padding:40px 0px;
}

.events-detail-main .reg-btn button {
  background-color: #fff;
  font-family: 'Roboto', sans-serif;
  color: #000;
  text-decoration: none;
  padding: 13px 35px;
  display: block;
  width: fit-content;
  margin-top: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}
/*/ === events detail page css ends === /*/



/*/ === members details page css starts === /*/
.member-banner {
  padding:0px 0% 50px !important;
  background-color:#000;
}

.member-banner .banner-bg {
  background-image:url('assets/img/member-banner.jpg');
  background-position:center;
  background-repeat:no-repeat;
  background-size: cover;
}

.member-banner .banner-bg h1 {
  font-family: 'Bebas Neue', cursive;
  text-align: center;
  color: #fff;
  padding: 100px 20px;
  font-size: 90px;
}

.members-main {
  background-color:#000;
  padding-left: 0px!important;
  padding-right: 0px!important;
}

.members-main .custom-container {
  padding: 40px 8%;
}

.members-main h4 {
  color: #dea62d;
  font-family: 'Bebas Neue', cursive;
  font-size: 40px;
  text-align: center;
  margin-bottom: 20px;
}

.members-main .committee-row {
  display: flex;
  flex-wrap: wrap;
}

.members-main .member-box {
  width: 20%;
  padding: 20px;
}

.members-main .member-box h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color:#5d5d5d;
  text-align: center;
  font-size: 18px;
  margin-top: 15px;
}

.members-main .member-box p {
  font-family: 'Roboto', sans-serif;
  color:#5d5d5d;
  font-weight: 400;
  text-align: center;
  font-size: 14px;
}

.members-main .other-members-list li  {
  font-family: 'Roboto', sans-serif;
  color: #bebebe;
  font-size: 18px;
  list-style: none;
  margin-bottom: 10px;
  border-bottom: 1px solid #151515;
  padding: 15px 0px;
}

.members-main .other-members-list ul {
  padding-left: 0px;
  column-count:3;
}
/*/ === members details page css ends === /*/



/*/ === gallery page css starts === /*/
.gallery-main {
  background-color:#000;
}

.gallery-main .custom-container {
  padding: 40px 8%;
}

.gallery-main .gallery-album-box img {
  height: 450px;
  object-fit: cover;
  transition: 0.5s ease-in;
}

.gallery-main .gallery-album-box img:hover {
  transform: scale(1.2);
}

.gallery-main .gallery-album-box {
  padding: 30px 20px;
}

.gallery-main .gallery-album-box .inner-box {
  overflow: hidden;
}

.gallery-main .gallery-album-box .inner-box {
  position: relative;
}

.gallery-main .gallery-album-box .inner-box h4 {
  position: absolute;
  font-family: 'Roboto', sans-serif;
  color: #fff;
  font-weight: 300;
  position: absolute;
  padding: 60px 20px 20px;
  position: absolute;
  width: 100%;
  background: rgb(0,0,0);
  background: linear-gradient(0deg, rgba(0,0,0,1) 16%, rgba(0,0,0,0) 100%); 
  bottom: 0px;
  margin-bottom: 0px;
}
/*/ === gallery page css ends === /*/



/*/ === gallery detail page css starts === /*/
.gallery-detail-main {
  background-color:#000;
}

.gallery-detail-main .custom-container {
  padding: 40px 8% 80px;
}

.gallery-detail-main .gallery-detail-box img {
  height: 450px;
  object-fit: cover;
  transition: 0.5s ease-in;
}

.gallery-detail-main .gallery-detail-box img:hover {
  filter: grayscale(1);
}

.gallery-detail-main .__react_modal_image__modal_content img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.gallery-detail-main .__react_modal_image__icon_menu a:first-child {
  display: none;
}
/*/ === gallery detail page css ends === /*/


/*/ === contact page css starts === /*/

.contact-main {
  background-color:#000;
}

.contact-main .custom-container {
  padding: 40px 8% 140px;
}

.contact-main .address-box h4 {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color:#fff;
  font-size: 14px;
}

.contact-main .address-box a {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color:#fff;
  font-size: 18px;
  display:block;
}

.contact-main .address-box p {
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #fff !important;
  font-size: 18px;
  margin-bottom: 0px;
}

.contact-main .address-box {
  padding: 60px 45px;
  background-color: #1f1f1f;
}

.contact-main .form-box {
  margin-top: 60px;
}

.contact-main .form-box h4 {
  color: #fff;
  font-family: 'Bebas Neue', cursive;
  font-size: 25px;
  text-align: left;
  margin-bottom: 20px;
}

.contact-main .form-box input {
  border:2px solid #181818;
  font-family: 'Roboto', sans-serif;
  background-color:#000000;
  border-radius:0px;
  height:55px;
  margin-bottom: 20px;
  color:#7e7e7e;
}

.contact-main .form-box input:focus-within {
  box-shadow:none;
}

.contact-main .form-box input[type="textarea"] {
  height:175px;
  overflow-y: scroll;
}

.contact-main .form-box::placeholder{
  font-family: 'Roboto', sans-serif;
  color:#2e2e2e !important;
  font-size: 14px;
}

.contact-main .form-box button {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #5d5d5d;
  padding: 16px 44px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  background-color:#000;
  border:none;
  margin-left: 12px;
}

.contact-main .form-box button:after {
  content:'';
  display: block;
  background-image:url('assets/img/btn-shape.png');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size:contain;
}

.contact-main .form-box button:hover {
  background-color:#000;
}

.contact-main .location-map iframe {
  width: 100%;
  height: 100%;
  filter: grayscale(1);
}

.contact-main .location-map {
  height: 100%;
  margin-top: 60px;
}
/*/ === contact page css ends === /*/


/*/ === member registration css starts === /*/
.member-registration-main {
  background-color:#000;
}

.member-registration-main .custom-container {
  padding: 40px 8% 100px;
}

.member-registration-main .form-box input {
  border: 2px solid #181818;
  font-family: 'Roboto', sans-serif;
  background-color: #000000;
  border-radius: 0px;
  height: 55px;
  margin-bottom: 20px;
  color: #7e7e7e;
}

.member-registration-main .form-box button {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #5d5d5d;
  padding: 16px 44px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  background-color: #000;
  border: none;
  margin: auto;
}

.member-registration-main .form-box button:after {
  content: '';
  display: block;
  background-image: url('assets/img/btn-shape.png');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.member-registration-main .form-box button:hover {
  background-color:#000;
}

.member-registration-main .form-box .fileinput {
  width: 200px;
  /* margin: auto; */
  position: relative;
  border: 2px solid #181818;
  padding: 25px;
  margin-bottom: 15px;
}

.member-registration-main .form-box .fileinput input {
  width: 199px;
  height: 169px;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
}

.member-registration-main .form-box .fileinput p {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  color:#7e7e7e;
}

.member-registration-main .form-box .fileinput img {
  width:60px;
  margin: auto;
  display: block;
}
/*/ === member registration css ends === /*/


/*/ === event registration css starts === /*/
.event-register-main {
  background-color:#000;
}

.event-register-main .custom-container {
  padding:40px 8% 80px;
}

.event-register-main .member-box h4 {
  color: #fff;
  font-family: 'Bebas Neue', cursive;
  font-size: 38px;
  text-align: left;
  margin-bottom: 5px;
}

.event-register-main .member-box h6 {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 18px;
  margin-bottom: 50px;
}

.event-register-main .member-box input {
  border: 2px solid #181818;
  font-family: 'Roboto', sans-serif;
  background-color: #000000;
  border-radius: 0px;
  height: 55px;
  margin-bottom: 25px;
  color: #7e7e7e;
}

.event-register-main .member-box .react-dropdown-select {
  border: 2px solid #181818;
  font-family: 'Roboto', sans-serif;
  background-color: #000000;
  border-radius: 0px;
  height: 55px;
  margin-bottom: 25px;
  color: #7e7e7e;
}

.event-register-main .member-box .react-dropdown-select input {
  margin-bottom: 0px;
  border:none;
  background:none;
}

.event-register-main .member-box label {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #bebebe !important;
  font-size: 16px;
  color:#747474;
  margin-bottom: 6px;
}

.event-register-main .member-box button {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #5d5d5d;
  padding: 16px 44px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  background-color: #000;
  border: none;
}

.event-register-main .member-box button:after {
  content: '';
  display: block;
  background-image: url('assets/img/btn-shape.png');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.event-register-main .member-box button:hover {
  background-color:#000;
}

.event-register-main .member-box p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 18px;
  margin-bottom: 50px;
  margin-top: 20px;
}

.event-register-main .member-box a {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 16px;
  margin-bottom: 50px;
  margin-top: 20px;
  text-decoration: underline !important;
}

.event-register-main .or-div p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 16px;
  margin-bottom: 50px;
  margin-top: 20px;
  text-align: center;
  position: relative;
}

.event-register-main .or-div p:before {
  content: '';
  display: block;
  width: 1px;
  height: 100px;
  background-color: #454545;
  position: absolute;
  margin: auto;
  left: 0px;
  right: 0px;
  bottom: 35px;
}

.event-register-main .or-div p:after {
  content: '';
  display: block;
  width: 1px;
  height: 100px;
  background-color: #454545;
  position: absolute;
  margin: auto;
  left: 0px;
  right: 0px;
  top: 35px;
}

.event-register-main .guest-register-box h4 {
  color: #fff;
  font-family: 'Bebas Neue', cursive;
  font-size: 38px;
  text-align: left;
  margin-bottom: 5px;
}

.event-register-main .guest-register-box p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #fff;
  font-size: 18px;
  margin-bottom: 50px;
}

.event-register-main .guest-register-box input {
  border: 2px solid #181818;
  font-family: 'Roboto', sans-serif;
  background-color: #000000;
  border-radius: 0px;
  height: 55px;
  margin-bottom: 25px;
  color: #7e7e7e;
}

.event-register-main .guest-register-box label {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #fff;
  font-size: 16px;
  color:#747474;
  margin-bottom: 6px;
}

.event-register-main .guest-register-box button {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #5d5d5d;
  padding: 16px 44px;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  background-color: #000;
  border: none;
}

.event-register-main .guest-register-box button:after {
  content: '';
  display: block;
  background-image: url('assets/img/btn-shape.png');
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.event-register-main .guest-register-box button:hover {
  background-color:#000;
}
/*/ === event registration css ends === /*/

.terms-main {
  background-color: #000;
}

.terms-main .custom-container {
  padding: 40px 7% 80px;
}

.terms-main p {
  font-family: 'Roboto', sans-serif;
  color: #5d5d5d;
  font-size: 16px;
  text-align: justify;
}


.terms-main h4 {
  font-family: 'Bebas Neue', cursive;
  color: #fff;
  font-weight: 300;
  font-size: 25px;
  margin:30px 0px;
}
/* .home-logo-res {
  position: absolute;
  right: 0;
  opacity: .8;
} */

@media only screen and (max-width:767.5px) {
  .home-committee .member-box {
    width:30%;
    margin:auto;
  }
  .main-events .event-box .event-content h6:before {
    width: 80px;
    left: 0px;
  }
  .main-events .event-box .event-content h6 {
    padding-left: 90px;
  }
  .main-events .event-box-two .event-content h6 {
    padding-right: 90px;
  }
  .main-events .event-box-two .event-content h6:before {
    width: 80px;
    right: 0px;
  }
  .footer-nav {
    display: flex;
    flex-direction: column;
  }
  .members-main .other-members-list ul {
   
    column-count:1;
  }
  .bgcolordata{
    height: 100vh;
  }
  .footer .footer-nav a {
    padding: 8px 35px;
  }
  .footer .custom-container {
    padding: 40px 8% 20px;
  }
  .events-banner .banner-bg h1 {
    padding: 100px 20px;
  }
  .main-events .custom-container {
    padding: 40px 3%;
  }
  .main-vision-mission .content-box {
    padding:30px 0px;
  }
  .navbar-collapse {
    position: absolute;
    background-color: #000;
    top: 125px;
    width: 85%;
    border-bottom:1px solid #5d5d5d;
  }
  .home-register .form-box {
    padding-left: 0px;
  }
  .event-register-main .or-div p:before {
    bottom: -38px;
    transform: rotate(90deg);
    left: -145px;
  }
  .event-register-main .or-div p:after {
    right: -143px;
    top: -38px;
    transform: rotate(90deg);
  }
  .home-slider img {
    height:auto;
  }
  .home-logo {
    height: auto;
  }
  div.home-logo-res {order: 1;}
  div.home-slider-res {order: 2;}

  .sl-section-right .navbar {
    top: 30px;
    right: 50px;
  }
  .footer .footer-nav a {
    border-right:none;
  }
  .members-main .committee-row {
    justify-content: center;
  }
  .members-main .member-box {
    width: 45%;
  }
  .footer .copyright p {
    text-align: center;
  }
  .footer .copyright .ft-right p {
    text-align: center;
  }
  .member-banner .banner-bg h1 {
    padding: 100px 20px;
    font-size: 45px;
  }
  .about-banner .banner-bg h1 {
    padding: 100px 20px;
    font-size: 45px;
  }
  .home-service .service-content {
    padding:75px 3%;
  }
  .home-events-main .events-list .custom-container {
    padding: 40px 10%;
  }
  .home-service .service-img {
    padding: 0px 2%;
  } 
  .home-register .custom-container {
    padding: 40px 7% 120px;
  }
  div.hm-service-second {order: 1;}
  div.hm-service-first {order: 2;}
  .member-registration-main .form-box .fileinput{
    margin: auto;
    margin-bottom: 15px!important;

  }
  .f-profile {
    float: unset!important;
    margin-bottom: 15px!important;
  }
}

@media only screen and (max-width:499px) {
  .home-committee .member-box {
    width:50%;
    margin:auto;
  }
  .sl-section-right .navbar {
    top: 30px;
  }
  
}

.button-addon2 {
  border: 2px solid #181818!important;
  font-family: 'Roboto', sans-serif!important;
  background-color: #000000!important;
  border-radius: 0px !important;
  height: 55px !important;
  margin-bottom: 25px !important;
  color: #7e7e7e !important;
}

.button-addon2:after{
  display: none!important;
}

.form-b input {
  width: 100%!important;
}
/* .r-user {
  display: none!important;
} */

.r-user h5{
  color: #747474;
}

.r-user p{
  color: #747474;
}
.member-registration-main select{
  border: 2px solid #181818;
    font-family: 'Roboto', sans-serif;
    background-color: #000000;
    border-radius: 0px;
    height: 55px;
    margin-bottom: 20px;
    color: #7e7e7e;
    width: 100%;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
}
.f-profile {
  float: right;
}

.text-danger {
  font-size: 18px!important;
}

.form-select {
  border: 2px solid #181818!important;
    font-family: 'Roboto', sans-serif;
    background-color: #000000!important;
    border-radius: 0px;
    height: 55px;
    margin-bottom: 25px;
    color: #7e7e7e!important;
}

.form-control {
  border: 2px solid #181818!important;
    font-family: 'Roboto', sans-serif;
    background-color: #000000!important;
    border-radius: 0px!important;
    height: 55px;
    margin-bottom: 20px;
    color: #7e7e7e!important;
}

.home-logo h5 {
  color: #dea62d;
  /* font-family: 'Bebas Neue', cursive; */
  font-family: 'Oswald', sans-serif;
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  margin-bottom: 10px;
}
.bgcolordata {
  background-color: #000;
}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
  height: 90px!important;
  width: 90px!important;
}

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.8;
  
}
.close:hover {
  opacity: 1;
}
.close:before, .close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #dea62d;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  vertical-align: middle;
  width: 100%;
  line-height: 0;
  height: 70px;
  object-fit: cover;
}

.amount{
  color: #dea62d;
}































.toast{
  width: 24rem;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 7px;
  display: grid;
  grid-template-columns: 1.3fr 6fr 0.5fr;
  box-shadow: 0 15px 30px rgba(0,0,0,0.08);
}
.success{
  border-left: 6px solid #47D764;
}
.error{
  border-left: 6px solid #ff355b;
}
.info{
  border-left: 6px solid #2F86EB;
}
.warning{
  border-left: 6px solid #FFC021;
}
.error i{
  color: #ff355b;
}
.info i{
  color: #2F86EB;
}
.warning i{
  color: #FFC021;
}
.toast:not(:last-child){
  margin-bottom: 20px;
}
.outer-container,.inner-container{
  align-self: center;
} 
.outer-container i{
  font-size: 35px;
}
.success i{ 
  color: #47D764;
}
.inner-container p:first-child{
  color: #101020;
  font-weight: 600;
  font-size: 16px;
}
.inner-container p:last-child{
  font-size: 12px;
  font-weight: 400;
  color: #656565;
}

.qrcodedata {
  height: 15rem;
  width: 15rem;
  display: block;
    margin: auto;
}
.logo-h {
  font-size: 22px!important;
}
.logo-b {
  margin-top: 10px;
}
.form-b textarea {
  
    resize: vertical;
    width: 100%;
    background: black;
    color: #bebebe;

}

.amount-box {
  color: #dea62d!important;
}


.cnt-success {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65vh;
}
.success-txt-aln {
  text-align: center;
}
.img-success-data {
  width: 10rem;
  height: 10rem;
  margin-bottom: 20px;
}
.txt-color-success {
  color: rgb(224, 224, 224);
}